import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout } from '../components/layout';
import '../styles/not-found.scss';

const IndexPage = () => (
  <Layout>
    <div className="not-found">
      <h2>These are not the droids you're looking for.</h2>
      <Link title="Back to homepage" to="/">
        <FontAwesomeIcon icon={faAngleLeft} /> Back to safety
      </Link>
    </div>
  </Layout>
);

export default IndexPage;
